export const LANGS = ["en", "zh-Hant", "zh-Hans", "ja", "ko"];

export const LANGS_URLS_FOR_ROOT = {
  en: "/en",
  "zh-Hant": "/zh-Hant",
  "zh-Hans": "/zh-Hans",
  ja: "/ja",
  ko: "/ko",
};

export const LANGS_MAP_FOR_ASSETS = {
  en: "en",
  "zh-Hant": "cht",
  "zh-Hans": "chs",
  ja: "jp",
  ko: "kr",
};

export const LANGS_URLS_AND_DISPLAY = [
  {
    key: "zh-Hant",
    url: "/zh-Hant",
    display: "繁",
  },
  {
    key: "zh-Hans",
    url: "/zh-Hans",
    display: "简",
  },
  {
    key: "en",
    url: "/en",
    display: "EN",
  },
  {
    key: "ja",
    url: "/ja",
    display: "日",
  },
  {
    key: "ko",
    url: "/ko",
    display: "한",
  },
];
