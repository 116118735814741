import React, { useEffect, useCallback } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { useIntl } from "gatsby-plugin-intl";
import config from "../config";
import { OuterLink } from "../components";
import { BREAK_POINTS, Z_INDEX } from "../utils/style";

const COLOR_TEXT = "#e71c4e";
const COLOR_HOVER = "#ff003f";

const textShadow = color => {
  return `0 0 5px ${color}, 0 0 10px ${color}, 0 0 15px ${color},
      0 0 20px ${color}, 0 0 10px ${color}, 0 0 20px ${color}`;
};

const NavbarWrapper = styled.nav`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${Z_INDEX.cover};

  a,
  span,
  div {
    display: inline-block;
    color: #ffffff;
    font-size: ${props => (props.lang === "en" || props.lang === "ja" ? "20px" : "22px")};
    font-weight: 700;
    letter-spacing: 8px;
    text-shadow: ${textShadow(COLOR_TEXT)};
    text-decoration: none;

    &:hover {
      text-shadow: ${textShadow(COLOR_HOVER)};
    }
  }

  a,
  div {
    cursor: pointer;
    margin: 0 14px;
    letter-spacing: ${props =>
      props.lang === "zh-Hant" || props.lang === "zh-Hans" ? "4px" : "0"};
  }

  span {
    width: 12px;
  }

  @media screen and (max-width: ${BREAK_POINTS.xl}) {
    display: none;
  }
`;

export default function NavbarPC({ toRoot = false }) {
  const intl = useIntl();
  const { locale, formatMessage } = intl;

  const handleScrollTo = useCallback(
    id => {
      if (toRoot) {
        if (typeof window !== "undefined") {
          window.sessionStorage.setItem("anchor", id);
          window.location.href = `../../`;
        }
      } else {
        if (typeof window !== "undefined") {
          const element = window.document.getElementById(id);
          if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    },
    [toRoot, locale]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const anchor = window.sessionStorage.getItem("anchor");
      if (anchor) {
        handleScrollTo(anchor);
        window.sessionStorage.removeItem("anchor");
      }
    }
  }, [handleScrollTo]);

  return (
    <NavbarWrapper lang={locale}>
      <Link to="/announcements">お知らせ</Link>
      <span>|</span>
      {config.navbar.map((nav, index) => (
        <React.Fragment key={index}>
          {nav.link[0] === "#" ? (
            <div role="button" onClick={() => handleScrollTo(nav.link.slice(1))}>
              {formatMessage({ id: `nav-${nav.key}` })}
            </div>
          ) : (
            <OuterLink
              href={nav.link[locale] || nav.link.en}
              text={formatMessage({ id: `nav-${nav.key}` })}
            />
          )}
          {index !== config.navbar.length - 1 && <span>|</span>}
        </React.Fragment>
      ))}
      <span>|</span>
      <OuterLink href="mailto:tkfmservice@pinkcore.com " text="お問合せ(メール)" />
    </NavbarWrapper>
  );
}
