import React from "react";
import styled from "@emotion/styled";
import { useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql, Link } from "gatsby";

const FooterContainer = styled.footer`
  width: 100%;
  background-color: #000000;
  padding: 30px 0 50px;
`;

const FooterBlock = styled.div`
  width: 590px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: 80%;
  }
`;

const FooterLinks = styled.div`
  text-align: center;
  margin-top: 50px;

  > div {
    display: inline-block;
    margin: 0 5px;
  }

  a {
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin: 0 2px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
`;

const Query = graphql`
  query Footer {
    policy: markdownRemark(
      frontmatter: { type: { eq: "announcement" }, slug: { eq: "dark-wind-policy" } }
    ) {
      frontmatter {
        slug
      }
    }
    announcement: markdownRemark(
      frontmatter: { type: { eq: "announcement" }, slug: { eq: "dark-announcement" } }
    ) {
      frontmatter {
        slug
      }
    }
    terms: markdownRemark(
      frontmatter: { type: { eq: "announcement" }, slug: { eq: "dark-wind-service-terms" } }
    ) {
      frontmatter {
        slug
      }
    }
    guidelines: markdownRemark(
      frontmatter: { type: { eq: "announcement" }, slug: { eq: "dark-wind-guidelines" } }
    ) {
      frontmatter {
        slug
      }
    }
  }
`;

export default function Footer() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const data = useStaticQuery(Query);

  return (
    <FooterContainer>
      <FooterBlock>
        <ul className="text-center text-white text-xs mb-4">
          <li>ゲーム情報</li>
          <li>ゲーム名称：天下布魔</li>
          <li>ジャンル：RPG</li>
          <li>対応言語: 日本語</li>
          <li>ゲーム価格：無料（ゲーム内課金あり）</li>
        </ul>

        <ul className="text-center text-white text-xs mb-4">
          <li className="text-base">動作環境</li>
          <li>DMM GAME PLAYER 版</li>
          <li>OS バージョン Windows 8.1/10 (32-bit or 64-bit)</li>
          <li>プロセッサ Core i3-3225/AMD A8-7650K 同等以上</li>
          <li>メモリ 4GB</li>
          <li>グラフィックス GeForce GT 640 or Radeon HD 5770 以上</li>
          <li>ディスク空き容量 2GB</li>
          <li>※すべての端末での動作を保障するものではございません。</li>
        </ul>

        <FooterLinks>
          <div>
            &#60;
            <Link to={`/announcements/${data.policy.frontmatter.slug}`}>
              {formatMessage({ id: "footer-privacy" })}
            </Link>
            &#62;
          </div>
          <div>
            &#60;
            <Link to={`/announcements/${data.terms.frontmatter.slug}`}>利用規約</Link>
            &#62;
          </div>
          <br />
          <div>
            &#60;
            <Link to={`/announcements/${data.announcement.frontmatter.slug}`}>
              特定商取引法に基づく表示
            </Link>
            &#62;
          </div>
          <div>
            &#60;
            <Link to={`/announcements/${data.guidelines.frontmatter.slug}`}>二次創作ルール</Link>
            &#62;
          </div>
        </FooterLinks>
      </FooterBlock>
      <small className="text-xs text-center block mt-4">
        © 2022 S.G.Art Ltd. / DarkWind Ltd.
        <br />
        TenkafuMA!™ All rights reserved.
      </small>
    </FooterContainer>
  );
}
