import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "gatsby-plugin-intl";

export default function MetaDataHead({ children }) {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <Helmet title={formatMessage({ id: "head-title" })}>
      <meta charset="utf-8" />
      <meta name="description" content={formatMessage({ id: "head-description" })} />

      <meta property="og:type" content="game" />
      <meta property="og:site_name" content={formatMessage({ id: "head-site-name" })} />
      <meta property="og:title" content={formatMessage({ id: "head-og-title" })} />
      <meta property="og:description" content={formatMessage({ id: "head-og-description" })} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={formatMessage({ id: "head-twitter-site" })} />
      <meta name="twitter:creator" content={formatMessage({ id: "head-twitter-creator" })} />

      <meta property="og:image" content={`${process.env.GATSBY_ROOT_PATH}/og.jpeg`} />
      <meta property="og:image:secure_url" content={`${process.env.GATSBY_ROOT_PATH}/og.jpeg`} />
      <meta property="og:url" content={formatMessage({ id: "head-og-url" })} />

      <link rel="icon" href={`${process.env.GATSBY_ROOT_PATH}/favicon.ico`} />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&amp;display=swap"
        rel="stylesheet"
      />

      {children}
    </Helmet>
  );
}
