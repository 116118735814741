import React from "react";

export default function DMM() {
  return (
    <div className="dmm fixed inset-x-0 top-0 bg-white text-[#333] z-30">
      <header className="dmm__header lg:hidden">
        <a href="https://games.dmm.co.jp/" className="ntg-top">
          FANZA GAMES
        </a>
        <div className="navi-right">
          <a
            href="https://point.dmm.co.jp/choice/pay?basket_service_type=freegame"
            className="arrow">
            ポイントチャージ
          </a>
        </div>
      </header>
      <div id="dmm_ntgnavi" className="hidden lg:flex">
        <ul className="navi_left">
          <li className="ntg_top">
            <a href="https://games.dmm.co.jp/">
              <span>FANZA GAMESトップ</span>
            </a>
          </li>
          <li className="ntg_poi">
            <a
              href="https://point.dmm.co.jp/choice/pay?basket_service_type=freegame"
              rel="noopener noreferrer"
              target="_blank">
              <span>ポイントチャージ</span>
            </a>
          </li>
        </ul>
        <ul className="navi_right">
          <li>
            <a
              href="https://www.dmm.co.jp/netgame/top/guide/startguide_html/=/ch_navi=/"
              className="arrow">
              初めての方
            </a>
          </li>
        </ul>
        <div className="clear"></div>
      </div>
    </div>
  );
}
