import React, { useEffect, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useIntl } from "gatsby-plugin-intl";
import config from "../config";
import { OuterLink } from "../components";
import { Z_INDEX } from "../utils/style";
import menuIcon from "../assets/images/mobile/m-menu-btn-01.png";
import closeIcon from "../assets/images/mobile/m-menu-btn-x.png";
import menuNavBg from "../assets/images/mobile/m-menu-btn-02.png";
import { Link } from "gatsby";

const MenuWrapper = styled.div`
  position: relative;
  z-index: ${Z_INDEX.cover};

  @media screen and (min-width: 1300px) {
    display: none;
  }
`;

const MenuIcon = styled.img`
  position: fixed;
  right: 10px;
  top: 50px;
  width: 50px;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    right: 20px;
    width: 60px;
  }
`;

const MenuPanel = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.78);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 60px;
  overflow-x: hidden;
  overflow-y: scroll;

  > a {
    display: block;
  }
`;

const MenuCloseBtn = styled.button`
  width: 28px;
  height: 36px;
  background: url(${closeIcon}) center/contain no-repeat;
  position: absolute;
  top: 16px;
  right: 20px;

  @media screen and (min-width: 768px) {
    top: 27px;
    right: 34px;
  }
`;

const MenuNav = styled.div`
  width: 80vw;
  max-width: 300px;
  height: 18vw;
  max-height: 60px;
  background: url(${menuNavBg}) center/contain no-repeat;
  margin: 8px auto;
  color: #ffffff;
  font-size: ${props => (props.locale === "en" || props.locale === "ja" ? "16px" : "20px")};
  font-weight: 700;
  padding-right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  a {
    &,
    &:active,
    &:focus {
      color: #ffffff;
    }
  }
`;


export default function NavbarMobile({ toRoot = false }) {
  const intl = useIntl();
  const { locale, formatMessage } = intl;
  const [openMenu, setOpenMenu] = useState(false);

  const handleScrollTo = useCallback(
    id => {
      if (toRoot) {
        if (typeof window !== "undefined") {
          window.sessionStorage.setItem("anchor", id);
          window.location.href = `../../`;
        }
      } else {
        if (typeof window !== "undefined") {
          const element = window.document.getElementById(id);
          if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
      setOpenMenu(false);
    },
    [toRoot, locale]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const anchor = window.sessionStorage.getItem("anchor");
      if (anchor) {
        handleScrollTo(anchor);
        window.sessionStorage.removeItem("anchor");
      }
    }
  }, [handleScrollTo]);

  return (
    <MenuWrapper>
      {!openMenu && (
        <MenuIcon
          onClick={() => {
            setOpenMenu(true);
          }}
          src={menuIcon}
          alt="menu"
        />
      )}
      {openMenu && (
        <MenuPanel>
          <MenuCloseBtn
            onClick={() => {
              setOpenMenu(false);
            }}></MenuCloseBtn>

          <Link to="/announcements">
            <MenuNav>お知らせ</MenuNav>
          </Link>
          {config.navbar.map((nav, index) => (
            <React.Fragment key={index}>
              {nav.link[0] === "#" ? (
                <MenuNav onClick={() => handleScrollTo(nav.link.slice(1))} locale={locale}>
                  {formatMessage({ id: `nav-${nav.key}` })}
                </MenuNav>
              ) : (
                <MenuNav locale={locale}>
                  <OuterLink
                    href={nav.link[locale] || nav.link.en}
                    text={formatMessage({ id: `nav-${nav.key}` })}
                  />
                </MenuNav>
              )}
            </React.Fragment>
          ))}
          <MenuNav>
            <OuterLink href="mailto:tkfmservice@pinkcore.com " text="お問合せ(メール)" />
          </MenuNav>
        </MenuPanel>
      )}
    </MenuWrapper>
  );
}
