import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import slideBg from "../assets/images/home/side-slider-bg.png";
import { BREAK_POINTS, Z_INDEX } from "../utils/style";
import { OuterLink } from "../components";

const SliderContainer = styled.div`
  width: 271px;
  height: 176px;
  background: url(${slideBg}) center/contain no-repeat;
  margin-right: -8px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 80vw;
    height: 50vw;
    margin: -21vw auto 0;
    background: #ee2478;

    &,
    &:active,
    &:focus {
      border: 3px solid #2b000c !important;
      border-radius: 12px;
    }
  }
`;

const SlideBlock = styled.section`
  animation: fadeIn 0.8s ease-in;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    height: 100%;
  }
`;

const SlideInner = styled.div`
  width: 238px;
  height: 90px;
  margin: 15px 2px 0 20px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: calc(100% - 10px);
    height: 62%;
    margin: 5px auto 0;
  }
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const SlideDate = styled.div`
  width: 100%;
  height: 20px;
  font-size: 13px;
  font-weight: 700;
  background-color: rgba(52, 0, 0, 0.8);
  margin-top: -20px;
  z-index: ${Z_INDEX.title};
  position: relative;
  padding: 0 10px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    height: 25px;
    line-height: 25px;
    margin-top: -30px;
  }
`;

const SlideTitle = styled.div`
  width: calc(100% - 50px);
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  margin: 8px 20px 0 30px;
  overflow: hidden;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: calc(100% - 16px);
    height: 40px;
    margin: 5px auto 0;
    text-align: left;
    font-size: 14px;
  }

  @media screen and (max-width: 350px) {
    height: 30px;
    font-size: 12px;
    line-height: 1.2;
  }
`;

const SlideControl = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin: 0 3px;
    border: 2px solid #ffffff !important;
    border-radius: 100%;
    cursor: pointer;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    margin-top: -28px;
  }
`;

export default function HomeSlider({ slides }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    let slideInterval;

    if (slides.length > 1) {
      slideInterval = setInterval(() => {
        setCurrentSlide(currentSlide + 1 === slides.length ? 0 : currentSlide + 1);
      }, 6000);
    }

    return () => {
      clearInterval(slideInterval);
    };
  }, [setCurrentSlide, currentSlide, slides]);

  return (
    <SliderContainer>
      {slides.map(
        (slide, index) =>
          currentSlide === index && (
            <SlideBlock key={`slide_${index}`}>
              <SlideInner>
                <OuterLink href={slide.frontmatter.link}>
                  <SlideImage
                    src={require(`../assets/images/indexSlides${slide.frontmatter.image}`).default}
                    alt="slide"
                  />
                </OuterLink>
                <SlideDate>{slide.frontmatter.date.slice(0, 10)}</SlideDate>
              </SlideInner>
              <SlideTitle>{slide.frontmatter.title}</SlideTitle>
            </SlideBlock>
          )
      )}

      <SlideControl>
        {slides.map((_, index) => (
          <button
            key={`control_${index}`}
            onClick={() => setCurrentSlide(index)}
            style={{
              backgroundColor: currentSlide === index ? "#ffffff" : "rgba(0,0,0,0)",
            }}>
            {" "}
          </button>
        ))}
      </SlideControl>
    </SliderContainer>
  );
}
