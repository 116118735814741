import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { LANGS_URLS_AND_DISPLAY } from "../utils/languages";
import langSwitcherBg from "../assets/images/lang-pl-bg.png";
import { useIntl } from "gatsby-plugin-intl";
import { BREAK_POINTS, Z_INDEX } from "../utils/style";

const LangSwitcherWrapper = styled.ul`
  width: 240px;
  height: 45px;
  position: fixed;
  top: 20px;
  right: 40px;
  background: url(${langSwitcherBg}) center/contain no-repeat;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 8px 0;
  z-index: ${Z_INDEX.fixed};

  a {
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }

  span {
    margin: 6px;
  }

  @media screen and (max-width: ${BREAK_POINTS.xl}) {
    display: none;
  }
`;

export default function LangSwitcher({ destination }) {
  const intl = useIntl();
  const { locale } = intl;

  return (
    <LangSwitcherWrapper>
      {LANGS_URLS_AND_DISPLAY.map((lang, index) => (
        <React.Fragment key={index}>
          {/* TODO: maybe this can be replaced by changeLocale function of gatsby-plugin-intl, but there are bugs with redirection */}
          <Link
            to={destination[lang.key]}
            style={{ color: locale === lang.key ? "#ff0a94" : "#ffffff" }}>
            {lang.display}
          </Link>
          {index !== LANGS_URLS_AND_DISPLAY.length - 1 && <span>\</span>}
        </React.Fragment>
      ))}
    </LangSwitcherWrapper>
  );
}
