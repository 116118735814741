import styled from "@emotion/styled";
import { containerStyle, BREAK_POINTS } from "../utils/style";

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url("${process.env.GATSBY_ROOT_PATH}/bg-01.jpg") center/cover no-repeat;
  overflow: hidden;
  position: relative;
`;

export const PageWrapper = styled.div`
  ${containerStyle}
  height: 100%;
  padding: 70px 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    padding: 15px 0;
  }
`;

export const ContentWrapper = styled.section`
  width: 90%;
  max-width: 800px;
  background-color: rgba(51, 1, 25, 0.88);
  padding: 40px;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;

  p {
    margin: 10px 0;
  }

  h4 {
    margin: 20px 0 5px;
  }

  h1 {
    text-align: center;
  }

  li {
    list-style: none;
  }

  .list__level--2 {
    padding-left: 15px;
  }

  .list__level--3 {
    padding-left: 30px;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    padding: 15px 20px;
  }
`;
