export const BREAK_POINTS = {
  xl: "1299px",
  lg: "1023px",
  md: "767px",
  sm: "350px",
};

export const containerStyle = `
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export const Z_INDEX = {
  cover: 30,
  fixed: 25,
  title: 10,
  clickable: 8,
};
