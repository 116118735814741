import React from "react";
import styled from "@emotion/styled";
import scrollDownBtn from "../assets/images/home/btn-scroll-down.png";
import { BREAK_POINTS } from "../utils/style";

const ScrollDownIcon = styled.div`
  width: 93px;
  height: 149px;
  background: url(${scrollDownBtn}) center/contain no-repeat;
  position: absolute;
  bottom: 10px;
  left: 48%;
  translate: transformX(-50%);
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    display: none;
  }
`;

export default function ScrollDownBtn() {
  const handleScroll = () => {
    if (typeof window !== "undefined") {
      const element = window.document.getElementById("intro");
      if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return <ScrollDownIcon onClick={handleScroll} />;
}
