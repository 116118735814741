import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import closeIcon from "../assets/images/btn-x.png";
import { BREAK_POINTS, Z_INDEX } from "../utils/style";

const PopUpWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.cover};
  text-align: center;
`;

const PopUpImage = styled.img`
  max-height: 80vh;
  max-width: 80vw;
`;

const PopUpVideo = styled.video`
  max-height: 80vh;
  max-width: 80vw;
`;

export default function PopUp({ img, video, onClose }) {
  return (
    <PopUpWrapper>
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}>
        <button
          css={css`
            width: 50px;
            position: absolute;
            top: -50px;
            right: -50px;

            @media screen and (max-width: ${BREAK_POINTS.md}) {
              width: 30px;
              top: -30px;
              right: -30px;
            }
          `}
          onClick={onClose}>
          <img
            css={css`
              width: 100%;
              opacity: 0.8;

              &:hover {
                opacity: 1;
              }

              @media screen and (max-width: ${BREAK_POINTS.lg}) {
                opacity: 1;
              }
            `}
            src={closeIcon}
            alt="close"
          />
        </button>
        {img && <PopUpImage src={img} alt="popup" />}
        {video && <PopUpVideo src={video} controls />}
      </div>
    </PopUpWrapper>
  );
}
