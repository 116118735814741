import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import scrollToTopBtn from "../assets/images/back-to-top.png";
import { BREAK_POINTS, Z_INDEX } from "../utils/style";

const ScrollToTopIcon = styled.div`
  background: url(${scrollToTopBtn}) left center/contain no-repeat;
  position: fixed;
  width: 110px;
  height: 60px;
  right: 0;
  bottom: ${props => (props.show ? "30px" : "-60px")};
  cursor: pointer;
  opacity: 0.8;
  z-index: ${Z_INDEX.fixed};
  transition: bottom 0.1s ease-in;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 75px;
    height: 55px;
    bottom: ${props => (props.show ? "30px" : "-70px")};
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 55px;
    height: 45px;
    bottom: ${props => (props.show ? "10px" : "-50px")};
  }
`;

export default function ScrollToTop() {
  const [show, setShow] = useState(false);

  const scrollToTop = useCallback(() => {
    if (typeof window !== "undefined") {
      const element =
        window.document.getElementById("home") || window.document.getElementById("back-to-home");
      if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  // the scroll event should bind on containers
  useEffect(() => {
    const target_element =
      document.getElementById("content-container") ||
      (typeof window !== "undefined" ? window : null);

    const handleScroll = () => {
      const t = 600;
      if (target_element) {
        if (target_element.scrollTop >= t || target_element.pageYOffset >= t) {
          setShow(true);
        } else if (target_element.scrollTop < t || target_element.pageYOffset < t) {
          setShow(false);
        }
      }
    };

    if (target_element) target_element.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      if (target_element) target_element.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <ScrollToTopIcon show={show} onClick={scrollToTop} />;
}
